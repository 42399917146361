<template>
  <v-container class="text-top">
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="450"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-alert dense type="success">
            {{ dialogHead }}
          </v-alert>
          <v-card-text>{{ dialogMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="dialog = false"> 확인 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import EventBus from "./EventBus";

export default {
  data() {
    return {
      dialog: false,
      dialogHead: "",
      dialogMessage: "",
      count: 0,
    };
  },
  computed: {
    height1() {
      return this.dialogHead;
    },
  },
  created() {
    EventBus.$on("push-msg", (payload) => {
      this.dialogHead = payload.Head;
      this.dialogMessage = payload.Message;
      this.count++;
      this.dialog = true;
      console.log(this.count);
    });
  },
};
</script>
