<template>
  <div>
    <v-container fluid class="grey lighten-5">
      <v-row align="center">
        <v-col class="text-center elevation-1" cols="12">
          <h2>사용자 관리</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="grey lighten-5">
      <v-row dense class="grey lighten-5 ml-1 mr-1 gutters">
        <v-col class="xs" cols="12" sm="3">
          <v-text-field v-model="mUSE_ID" label="로그인 ID" required />
        </v-col>
        <v-col class="xs" cols="12" sm="3">
          <v-text-field v-model="mSUSE_FIRSR_NAME" label="성" required />
        </v-col>
        <v-col class="xs" cols="12" sm="3">
          <v-text-field v-model="mUSE_LAST_NAME" label="이름" required />
        </v-col>
      </v-row>
      <v-row class="grey lighten-5 ml-2 mr-2">
        <v-col style="overflow: auto">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-col>
        <v-col class="xs align" cols="12" sm="6">
          <v-btn class="mr-2" color="success" elevation="4" @click="onReset">
            초기화
          </v-btn>
          <v-btn class="mr-2" color="success" elevation="4" @click="onSubmit">
            조회
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-app>
      <v-container fluid class="grey lighten-5">
        <v-row class="grey lighten-5">
          <v-col style="overflow: auto">
            <v-data-table
              :headers="headers3"
              :items="desserts"
              class="elevation-1 grey lighten-5"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">사용자 초기화 </span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-checkbox
                                v-model="editedItem.USE_YN"
                                :label="`사용여부: ${editedItem.USE_YN.toString()}`"
                                color="success"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-checkbox
                                v-model="editedItem.USE_INIT_YN"
                                :label="`페스워드 초기화: ${editedItem.USE_INIT_YN.toString()}`"
                                color="success"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="use_password"
                                label="초기화 페스워드"
                                color="success"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="success" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="success" text @click="save"> Save </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-4" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <dialog-msg />
    </v-app>
  </div>
</template>

<script>
import EventBus from "./EventBus";
import DialogMsg from "./dialogMessage.vue";

import { mapActions } from "vuex"; // mapGetters를 추가한다

const register = "register";

export default {
  name: "UserListPage",
  components: {
    DialogMsg,
  },
  data() {
    return {
      use_password: "tomato",
      dialog: false,
      messageDialog: false,
      dialogMessage: "",
      search: "",
      desserts: [],
      mUSE_ID: "",
      mSUSE_FIRSR_NAME: "",
      mUSE_LAST_NAME: "",
      headers: [
        { text: "사용자ID", value: "USE_ID", width: "100px", fixed: true },
        { text: "성", value: "USE_FIRSR_NAME", width: "100px", fixed: true },
        { text: "이름", value: "USE_LAST_NAME", width: "100px", fixed: true },
        { text: "사용여부", value: "USE_YN", width: "100px" },
        { text: "초기화여부", value: "USE_INIT_YN", width: "120px" },
        { text: "Actions", value: "actions", width: "100px", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        USE_YN: "",
        USE_INIT_YN: "",
      },
    };
  },
  computed: {
    headers0() {
      return this.headers.map((header) =>
        Object.assign({}, header, { fixed: false })
      );
    },
    headers2() {
      return [
        { text: "", fixed: true, width: "100px", sortable: false },
      ].concat(this.headers);
    },
    headers3() {
      return [
        { text: "", value: "data-table-expand", fixed: true, width: "10px" },
      ].concat(this.headers);
    },
    headers5() {
      return this.headers3;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions(register, ["userInityn", "createHashedPassword", "userList"]),
    async fuserInityn() {
      try {
        const use_password = await this.createHashedPassword(this.use_password);
        const getResult = await this.userInityn({
          fun_code: "init",
          use_id: this.editedItem.USE_ID,
          use_password: use_password,
          use_yn: this.editedItem.USE_YN,
          use_init_yn: this.editedItem.USE_INIT_YN,
        });
        console.log("loginResult", getResult);
        if (getResult.data.repCode === "00") {
          this.dialogHead = "info";
          this.dialogMessage = getResult.data.repMessage;
        } else {
          this.dialogHead = "error";
          this.dialogMessage = getResult.data.repMessage;
        }
        let payload = { Head: this.dialogHead, Message: this.dialogMessage };
        EventBus.$emit("push-msg", payload);
      } catch (err) {
        console.error(err);
      }
    },
    async onSubmit() {
      try {
        const getResult = await this.userList({
          fun_code: "list",
          use_id: this.mUSE_ID,
          use_firsr_name: this.mSUSE_FIRSR_NAME,
          use_last_name: this.mUSE_LAST_NAME,
        });
        console.log("loginResult", getResult);
        if (getResult) {
          this.desserts = getResult.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    onReset() {
      this.mUSE_ID = "x";
      this.onSubmit();
      this.mUSE_ID = "";
      this.mSUSE_FIRSR_NAME = "";
      this.mUSE_LAST_NAME = "";
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.USE_YN === "0") {
        this.editedItem.USE_YN = "";
      }
      if (this.editedItem.USE_INIT_YN === "0") {
        this.editedItem.USE_INIT_YN = "";
      }
      console.log("editItem", this.editedItem);
      console.log("editItem", this.editedItem.USE_INIT_YN);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    save() {
      this.fuserInityn();
      this.onSubmit();
      this.close();
    },
  },
};
</script>

<style scoped>
v-text-field {
  background-color: #6ec0e9;
}
</style>
