<template>
  <user-list-page />
</template>

<script>
import userListPage from "../components/userListPage";

export default {
  name: "UserList",
  components: {
    userListPage,
  },
  data() {
    return {};
  },
  methods: {
    //
  },
};
</script>
<style scoped>
.alert-danger p {
  color: red;
}
</style>
